import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
// import { ReactComponent as LinkIcon } from "assets/svg/link-icon.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import { ReactComponent as ReportIconTriangle } from "assets/svg/report-icon-triangle.svg";
import classNames from "classnames/bind";
import { CustomTooltip } from "components/CustomTooltip/CustomTooltip";
// import { ShareDataBox } from "components/ShareDataBox/ShareDataBox";
import { MODAL_TYPE, PATHS } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "redux/messengerSlice";
import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./SidebarMenuTooltip.module.scss";

export const SidebarMenuTooltip = ({ influencerData, btnClassName }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openTooltip, setOpenTooltip] = useState(false);

  const goToMessenger = () => {
    const { appUserId, profileImageUrl, handle, name } = influencerData || {};
    dispatch(
      setSelectedUser({
        id: appUserId,
        profileImageUrl,
        username: handle || name,
      })
    );
    navigate(PATHS.CHAT_DIRECT.replace(":userId", appUserId));
  };

  const reportHandler = () => {
    dispatch(
      openModal({
        type: MODAL_TYPE.REPORT_MODAL,
        recordId: influencerData?.appUserID,
        contentType: "user",
      })
    );
  };

  const btnClass = classNames(styles.innerBtnPopover, {
    [btnClassName]: btnClassName,
  });

  return (
    <CustomTooltip
      open={openTooltip}
      setOpen={setOpenTooltip}
      isClick={true}
      title={
        <Box className={styles.tooltipContent}>
          <Typography
            variant="body2"
            className={styles.tooltipItem}
            onClick={() => {
              setOpenTooltip(false);
              goToMessenger();
            }}
          >
            <MessageIcon />
            {t("messages.sendMessage")}
          </Typography>
          {/*<ShareDataBox*/}
          {/*  title={t("edition.share")}*/}
          {/*  buttonClassName={styles.tooltipItemShare}*/}
          {/*  onClick={() => setOpenTooltip(false)}*/}
          {/*>*/}
          {/*  <Typography variant="body2" className={styles.tooltipItem}>*/}
          {/*    <LinkIcon />*/}
          {/*    {t("edition.share")}*/}
          {/*  </Typography>*/}
          {/*</ShareDataBox>*/}
          <Typography
            variant="body2"
            className={styles.tooltipItem}
            onClick={reportHandler}
          >
            <ReportIconTriangle />
            {t("account.report")}
          </Typography>
        </Box>
      }
      placement="bottom"
    >
      <IconButton className={btnClass}>
        <DotsEditIcon />
      </IconButton>
    </CustomTooltip>
  );
};
