import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getPublicUser,
  getPublicUserLoading,
} from "redux/selectors/usersSelectors";
import { clearPublicUser, fetchPublicUser, searchUser } from "redux/usersSlice";
import { getAppUserPosts } from "services/api/feedApi";
import { LocalizationContext } from "services/localizationContext";
import { getUsernameFromPathname } from "utils/usernameHelper";

import { NewProfile } from "./NewProfile";
import styles from "./NewProfileWrapper.module.scss";

export const NewUserProfile = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const publicUser = useSelector(getPublicUser);
  const publicUserIsLoading = useSelector(getPublicUserLoading);

  const username = params?.id || getUsernameFromPathname(location.pathname);

  const [postsData, setPostsData] = useState({
    posts: {
      left: [],
      right: [],
      all: [],
    },
    total: 0,
  });
  const [postsLoader, setPostsLoader] = useState(false);
  const [postsPage, setPostsPage] = useState(1);
  const [publicSearchUserLoading, setPublicSearchUserLoading] = useState(true);

  const hasMorePosts =
    postsData.total >
    postsData.posts.left.length + postsData.posts.right.length;

  const loadMorePosts = () => {
    setPostsPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (!username) {
      return;
    }

    dispatch(searchUser(username))
      .unwrap()
      .then((user) => {
        dispatch(fetchPublicUser(user?.id));
      })
      .catch((err) => {
        console.log("Cannot get public user", err);
      })
      .finally(() => setPublicSearchUserLoading(false));
  }, [username]);

  useEffect(() => {
    if (publicUser.id) {
      setPostsLoader(true);
      getAppUserPosts({
        appUserID: publicUser.id,
        params: {
          limit: 20,
          page: 1,
        },
      })
        .then((res) => {
          const params = {
            total: res.total,
            posts: {
              left: [],
              right: [],
              all: res.posts,
            },
          };
          res.posts.forEach((item, index) => {
            if (index % 2 === 0) {
              params.posts.left.push(item);
            } else {
              params.posts.right.push(item);
            }
          });
          setPostsData(params);
        })
        .finally(() => setPostsLoader(false));
    }
  }, [publicUser.id]);

  useEffect(() => {
    if (postsPage > 1) {
      getAppUserPosts({
        appUserID: publicUser.id,
        params: {
          limit: 20,
          page: postsPage,
        },
      }).then((res) => {
        const posts = {
          left: [],
          right: [],
        };
        res.posts.forEach((item, index) => {
          if (index % 2 === 0) {
            posts.left.push(item);
          } else {
            posts.right.push(item);
          }
        });
        setPostsData((prevState) => ({
          ...prevState,
          posts: {
            left: [...prevState.posts.left, ...posts.left],
            right: [...prevState.posts.right, ...posts.right],
            all: [...prevState.posts.all, ...res.posts],
          },
        }));
      });
    }
  }, [postsPage]);

  useEffect(() => {
    return () => {
      dispatch(clearPublicUser());
    };
  }, [params.id]);

  return (
    <NewProfile
      title={
        <Box className={styles.header}>
          <>{t("default.userProfileTitle", { name: username })}</>
        </Box>
      }
      me={{
        id: publicUser.id,
        level: publicUser.level,
        stats: publicUser.stats,
      }}
      profile={{
        userPicCroppedUrl: publicUser.profileImageUrl,
        username: publicUser.username,
      }}
      loading={publicUserIsLoading || publicSearchUserLoading}
      editions={publicUser.cards || []}
      editionLoader={publicUserIsLoading || publicSearchUserLoading}
      postsData={postsData}
      loadMorePosts={loadMorePosts}
      hasMorePosts={hasMorePosts}
      postsLoader={postsLoader}
      isPublic={true}
    />
  );
};
