import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames/bind";
import React from "react";
import { useLocation } from "react-router-dom";

import BackBtn from "../../components/Layout/BackBtn/BackBtn";
import { BottomMenu } from "../../components/Layout/BottomMenu/BottomMenu";
import Header from "../../components/Layout/Header/Header";
import { PATHS } from "../../constants";
import { isAuthenticated } from "../../services/auth.service";
import styles from "./PageLayout.module.scss";

const HIDE_HEADER_ROUTE = [
  PATHS.EMAIL_VERIFICATION,
  PATHS.COMPLETE_PROFILE,
  PATHS.BLOCKED,
];

export const PageLayout = ({
  title,
  children,
  isBack,
  backRoute,
  pageContentClassName,
  pageWrapperClassName,
  pageClassName,
  headerClassName,
  state,
  isPlain,
  onBackBtnClick,
  isTitleUppercase = true,
  backBtnProps = {},
  isSearch,
  customMobileHeaderMenu,
  isLogo = false,
  isShowHeader = true,
  isBottomMenuHide = false,
}) => {
  let location = useLocation();
  const theme = useTheme();

  const pageClass = classNames(styles.page, {
    [pageClassName]: pageClassName,
  });
  const pageContentClass = classNames(styles.pageContent, {
    [pageContentClassName]: pageContentClassName,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const isHideHeader =
    HIDE_HEADER_ROUTE.includes(location.pathname) || !isShowHeader;

  const isMainScreen =
    location.pathname.split("/").length === 2 ||
    location.pathname.includes(PATHS.CHAT);

  const isHistoryExist = location.key !== "default";

  const isExcludedPath =
    !location.pathname.includes(PATHS.LOGIN) &&
    !location.pathname.includes(PATHS.SIGN_UP) &&
    !location.pathname.includes(PATHS.FORGOT_PASSWORD_CREATOR) &&
    !location.pathname.includes(PATHS.FORGOT_PASSWORD_FAN) &&
    !location.pathname.includes(PATHS.PAGE_404) &&
    !location.pathname.includes(PATHS.PAGE_500) &&
    !location.pathname.includes(PATHS.PAGE_503);

  const isBottomMenu =
    (isMobile && isExcludedPath && isMainScreen && !isBottomMenuHide) ||
    (!isHistoryExist && isMobile && isExcludedPath);

  const pageLayoutWrapperClass = classNames(styles.pageLayoutWrapper, {
    [pageWrapperClassName]: pageWrapperClassName,
    [styles.pageLayoutMenu]: isBottomMenu,
  });

  const HeaderTitle = () => (
    <Typography
      variant="h1"
      className={`${styles.pageTitle} ${
        headerClassName ? headerClassName : ""
      } ${!isTitleUppercase ? styles.pageTitleInitial : ""}`}
    >
      {title}
    </Typography>
  );

  return (
    <Box className={pageLayoutWrapperClass}>
      {isAuthenticated() && !isHideHeader && (
        <Header
          backBtn={
            isMobile && isBack ? (
              <BackBtn
                title={title ? title : null}
                className={headerClassName ? headerClassName : ""}
                link={backRoute}
                state={state}
                isPlain={isPlain}
                onClick={onBackBtnClick}
                {...backBtnProps}
              />
            ) : null
          }
          isSearch={isSearch}
          customMobileHeaderMenu={customMobileHeaderMenu}
          isLogo={isLogo}
          title={<HeaderTitle />}
        />
      )}
      <Box className={pageClass}>
        {isBack && !isMobile && isShowHeader ? (
          <BackBtn
            title={title ? title : null}
            className={headerClassName ? headerClassName : ""}
            link={backRoute}
            state={state}
            isPlain={isPlain}
            onClick={onBackBtnClick}
            {...backBtnProps}
          />
        ) : !isBack && title && !isMobile && isShowHeader ? (
          <HeaderTitle />
        ) : null}
        <Box className={pageContentClass}>{children}</Box>
      </Box>
      {isBottomMenu ? <BottomMenu /> : null}
    </Box>
  );
};
