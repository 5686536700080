import { AppBar, Box, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { GLOBAL_HEADER_CLASSNAME, PATHS } from "constants/index";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchNotificationsStatus } from "redux/notificationsSlice";

import {
  getFilteredInfluencers,
  initialDataState,
  initialLoaders,
} from "../../../utils/getFilteredInfluencers";
import { SearchBar } from "../../SearchBar/SearchBar";
import { MessagesIcon } from "./components/MessagesIcon";
import { NavMenu } from "./components/NavMenu/NavMenu";
import { NotificationsIcon } from "./components/NotificationsIcon";
import { ProfileNav } from "./components/ProfileNav/ProfileNav";
import { SearchResultList } from "./components/SearchResultList/SearchResultList";
import styles from "./Header.module.scss";

export default function Header(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const [loaders, setLoaders] = useState(initialLoaders);
  const [data, setData] = useState(initialDataState);
  const [isResultOpened, setResultOpen] = useState(false);
  const [isSearchActive, setActiveCallback] = useState(false);

  const handleSearch = (searchTerm) => {
    getFilteredInfluencers({ params: { searchTerm }, setLoaders, setData });
    setResultOpen(true);
  };

  useEffect(() => {
    dispatch(fetchNotificationsStatus());
  }, []);

  if (
    !isMobile &&
    (window.location.href.includes(PATHS.SIGN_UP) ||
      window.location.href.includes(PATHS.LOGIN) ||
      window.location.href.includes(PATHS.FORGOT_PASSWORD_FAN) ||
      window.location.href.includes(PATHS.FORGOT_PASSWORD_CREATOR))
  ) {
    return null;
  }

  return (
    <Box
      className={`${styles.header} ${GLOBAL_HEADER_CLASSNAME} ${
        !props.isLogo ? styles.headerNoLogo : ""
      } `}
    >
      {props.backBtn ? (
        <Box className={styles.backBtnContainer}>
          {props.backBtn}
          {props.customMobileHeaderMenu && isMobile ? (
            <Box className={styles.iconsWrapper}>
              {props.customMobileHeaderMenu}
            </Box>
          ) : null}
        </Box>
      ) : (
        <AppBar
          className={styles.headerAppBar}
          position="static"
          color="dark"
          elevation={0}
        >
          <Toolbar className={styles.headerToolbar}>
            {!isMobile || props.isLogo ? (
              <Link to="/" className={styles.logo}>
                <Logo />
              </Link>
            ) : props.title ? (
              <>{props.title}</>
            ) : null}
            {isMobile ? (
              props.isSearch ? (
                <Box className={styles.mobileSearchWrapper}>
                  <SearchBar
                    onSeacrhChanged={handleSearch}
                    classNameWrapper={styles.search}
                    isAnimate
                    isResultOpened={isResultOpened}
                    setResultOpen={setResultOpen}
                    classNameResult={styles.searchResult}
                    className={styles.searchInput}
                    classNameInputAnimatedActive={styles.searchInputActive}
                    isShowMainSearchIcon={!isSearchActive}
                    setActiveCallback={setActiveCallback}
                  >
                    <SearchResultList data={data} loaders={loaders} />
                  </SearchBar>
                </Box>
              ) : props.customMobileHeaderMenu ? (
                <Box className={styles.iconsWrapper}>
                  {props.customMobileHeaderMenu}
                </Box>
              ) : (
                <Box className={styles.iconsWrapper}>
                  <MessagesIcon className={styles.messagesIcon} />
                  <NotificationsIcon className={styles.notificationIcon} />
                </Box>
              )
            ) : (
              <Box className={styles.headerMainSection}>
                <SearchBar
                  onSeacrhChanged={handleSearch}
                  classNameWrapper={styles.search}
                  isAnimate
                  isResultOpened={isResultOpened}
                  setResultOpen={setResultOpen}
                  classNameResult={styles.searchResult}
                >
                  <SearchResultList data={data} loaders={loaders} />
                </SearchBar>
                <NavMenu>
                  <Box className={styles.iconsWrapper}>
                    <MessagesIcon className={styles.messagesIcon} />
                    <NotificationsIcon className={styles.notificationIcon} />
                  </Box>
                  <ProfileNav />
                </NavMenu>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
}
