import "./App.scss";

import BackdropLoader from "components/Layout/BackdropLoader";
import PublicHeader from "components/Layout/PublicHeader/PublicHeader";
import AppSnackbar from "components/Layout/Snackbar/Snackbar";
import AppError from "components/Modal/ErrorModal/ErrorModal";
import Popup from "components/Modal/Modal";
import ZendeskWidget from "components/ZendeskWidget";
import Analytics from "modules/Analytics.module";
import React, { Suspense, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { fetchCountries, setSnackbar } from "redux/appSlice";
import {
  fetchUser,
  fetchUserConnections,
  fetchUserMe,
  updateUserProfile,
} from "redux/usersSlice";
import AnalyticsService from "services/Analytics.service";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { getUserLocale } from "utils/langHelper";
import { PublicRoute, RequireAuth } from "utils/routerGuards";
import Badges from "views/Badges/Badges";
import Barter from "views/Barter";
import Matches from "views/Barter/matches";
import Swiping from "views/Barter/swiping";
import Card from "views/Card";
import CardsForSale from "views/CardsForSale";
import Collections from "views/Collections/Collections";
import Editions from "views/Collections/Editions";
import DropBuy from "views/Drops/DropBuy";
import DropBuySuccess from "views/Drops/DropBuy/Success";
import FriendRequests from "views/FriendRequests";
import Kyc from "views/KYC/KYC";
import Onfido from "views/KYC/Onfido";
import NewProfile from "views/NewProfile/NewProfileWrapper";
import { CheckInfluencerHandle } from "views/Nomatch/CheckInfluencerHandle";
import AddBankAccount from "views/Payments/AddBankAccount/AddBankAccount";
import BankAddress from "views/Payments/AddBankAccount/BankAddress";
import SelectBankType from "views/Payments/AddBankAccount/SelectBankType";
import AddCard from "views/Payments/AddCard";
import BillingAddress from "views/Payments/AddCard/BillingAddress";
import SelectPaymentMethod from "views/Payments/SelectPaymentMethod";
import Post from "views/Post/index";
import PublicCollections from "views/Public/Collections/PublicCollections";
import PublicEditions from "views/Public/Collections/PublicEditions";
import ReferralsInfluencers from "views/Referrals/ReferralsInfluencers";
import Rewards from "views/Rewards";
import Account from "views/Settings/Account";
import EmailNotificationsSettings from "views/Settings/EmailNotifications";
import Faq from "views/Settings/Faq";
import Help from "views/Settings/Help";
import Policies from "views/Settings/Policies";
import Security from "views/Settings/Security";
import Settings from "views/Settings/Settings";
import Subscription from "views/Settings/Subscription";
import Transfers from "views/Settings/Transfers";
import IncomingSwaps from "views/Swaps/IncomingSwaps";
import SentSwaps from "views/Swaps/SentSwaps";
import SwapCreateRequest from "views/Swaps/SwapCreateRequest";
import SwapInnerPage from "views/Swaps/SwapInnerPage";
import Transactions from "views/Transactions";
import UserBlocked from "views/UserBlocked/";

// import AddFunds from "views/Wallet/AddFunds";
// import AddFundsAmount from "views/Wallet/AddFunds/Amount";
// import SelectAddFundsMethod from "views/Wallet/AddFunds/SelectMethod";
// import AddFundsSuccess from "views/Wallet/AddFunds/Success";
// import AddFundsByUSDC from "views/Wallet/AddFunds/USDC/AddFundsByUSDC";
// import SelectAddFundsUSDCNetwork from "views/Wallet/AddFunds/USDC/SelectUSDCNetwork";
// import WithdrawFunds from "views/Wallet/WithdrawFunds";
// import WithdrawFundsDetails from "views/Wallet/WithdrawFunds/ReviewDetails";
// import SelectWithdrawFundsAcc from "views/Wallet/WithdrawFunds/SelectBankAccount";
// import WithdrawFundsSuccess from "views/Wallet/WithdrawFunds/Success";
// import SelectWithdrawFundsUSDCNetwork from "views/Wallet/WithdrawFunds/USDC/SelectUSDCNetwork";
// import WithdrawFundsByUSDC from "views/Wallet/WithdrawFunds/USDC/WithdrawFundsByUSDC";
import MobileMenu from "./components/Layout/MobileMenu";
import { ScrollToTop } from "./components/Layout/ScrollToTop/ScrollToTop";
import { ChallengeSuccessModal } from "./components/Modal/ChallengeSuccessModal/ChallengeSuccessModal";
import {
  CONNECTIONS_LOAD_LIMIT,
  COOKIE,
  COOKIE_ACCEPTED,
  PATHS,
  SNACKBAR_TYPE,
  USER_BLOCKED,
  USER_STATUS_BLOCKED,
} from "./constants";
import { RedirectToPass } from "./Redirect";
import { fetchReactions } from "./redux/feedSlice";
import { getGlobalUnreadCountMessages } from "./redux/messengerSlice";
import BuyEdition from "./views/BuyEdition";
import { ChallengeCategory } from "./views/ChallengeCategory/ChallengeCategory";
import { ChallengesHub } from "./views/ChallengesHub/ChallengesHub";
import { ClubChallenges } from "./views/ClubChallenges/ClubChallenges";
import { Clubs } from "./views/Clubs/Clubs";
import Discover from "./views/Discover";
import { DiscoverAllStars } from "./views/Discover/subpages/DiscoverAllStars";
import DiscoverGroups from "./views/Discover/subpages/DiscoverGroups";
import { DiscoverRisingStars } from "./views/Discover/subpages/DiscoverRisingStars";
import { LimitedEdition } from "./views/Drops/LimitedEdition/LimitedEdition";
import Drops from "./views/Drops/PhaseList/PhaseList";
// import Phase from "./views/Drops/ViewPhase";
import EmailVerification from "./views/EmailVerification/EmailVerification";
import Error500 from "./views/ErrorPage/Error500";
import Error503 from "./views/ErrorPage/Error503";
import ForgotPassword from "./views/ForgotPassword";
import Home from "./views/Home/Home";
import Influencer from "./views/Influencer";
import InfluencerFollowers from "./views/Influencer/InfluencerFollowers";
import Login from "./views/Login";
import MeetingRoom from "./views/MeetingRoom/MeetingRoom";
import Messages from "./views/Messages";
import { NewChallengeDetails } from "./views/NewChallengeDetails/NewChallengeDetails";
import { NewConnections } from "./views/NewConnections/NewConnections";
import { NewUserProfile } from "./views/NewProfile/NewUserProfile";
import NoMatch from "./views/Nomatch";
import Notifications from "./views/Notifications";
import { Search } from "./views/Search/Search";
// import SellEdition from "./views/SellEdition";
import SignUp from "./views/Signup";
// import { Wallet } from "./views/Wallet/Wallet";
import WatchList from "./views/WatchList";

const Referrals = React.lazy(() => import("./views/Referrals/Referrals"));

function Router() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let location = useLocation();
  const { setLocalization } = useContext(LocalizationContext);

  const userMe = useSelector((state) => state.users.me.entities);
  const { error } = useSelector((state) => state.app.serverError) || {};

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(fetchCountries());
      dispatch(fetchReactions())
        .unwrap()
        .catch((err) => console.error("Cannot get reactions", err));
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    AnalyticsService.page(`${location.pathname}`, {
      userId: userMe.id,
      currency: userMe.currencyCode,
    });

    const cookie = localStorage.getItem(COOKIE);

    if (cookie !== COOKIE_ACCEPTED) {
      dispatch(
        setSnackbar({
          open: true,
          left: "40px",
          right: "40px",
          width: "auto",
          unclosable: true,
          autoHideDuration: null,
          type: SNACKBAR_TYPE.COOKIE,
        })
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (error === USER_BLOCKED || userMe.status === USER_STATUS_BLOCKED) {
      navigate(PATHS.BLOCKED, {
        replace: true,
      });
    }
  }, [error, userMe.status]);

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(fetchCountries());
      dispatch(getGlobalUnreadCountMessages());
      dispatch(fetchUser())
        .unwrap()
        .then((res) => {
          const { language, email } = res;
          const userLocale = getUserLocale();
          if (email && window.sib) {
            window.sib.email_id = email;
          }
          const selectedLang =
            localStorage.getItem("selected-language") || language || userLocale;

          dispatch(
            updateUserProfile({
              language: selectedLang,
            })
          ).then((res) => {
            if (res?.payload?.language) {
              setLocalization(res.payload.language);
            } else {
              setLocalization(selectedLang);
            }
          });

          localStorage.setItem("ii-language", userLocale);
        });
      dispatch(
        fetchUserConnections({
          limit: CONNECTIONS_LOAD_LIMIT,
          page: 1,
        })
      );
      dispatch(fetchUserMe());
    }
  }, [isAuthenticated()]);

  const headerRef = useRef();
  return (
    <>
      <Analytics />
      <ScrollToTop />
      <PublicHeader innerRef={headerRef} />
      <MobileMenu />
      <Suspense fallback={<></>}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route
              path="/"
              element={
                <Navigate
                  to={PATHS.SIGN_UP}
                  state={{ backRoute: location }}
                  replace
                />
              }
            />
            <Route path={PATHS.LOGIN} element={<Login />} />
            <Route path={PATHS.GOOGLE_SIGNUP} element={<Login />} />

            <Route path={PATHS.SIGN_UP} element={<SignUp />} />
            <Route path={PATHS.SIGN_UP_GIVEAWAY} element={<SignUp />} />
            <Route path="/sign-up/:code" element={<SignUp />} />
            <Route
              path={PATHS.FORGOT_PASSWORD_CREATOR}
              element={<ForgotPassword isCelebType={true} />}
            />
            <Route
              path={PATHS.FORGOT_PASSWORD_FAN}
              element={<ForgotPassword />}
            />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="/home" element={<Home />}>
              <Route path={PATHS.POST_ID} element={<Post />} />
            </Route>
            <Route path={PATHS.DISCOVER} element={<Discover />} />
            <Route
              path={PATHS.DISCOVER_ALL_STARS}
              element={<DiscoverAllStars />}
            />
            <Route
              path={PATHS.DISCOVER_RISING_STARS}
              element={<DiscoverRisingStars />}
            />
            <Route path={PATHS.DISCOVER_GROUPS} element={<DiscoverGroups />} />
            <Route path={PATHS.SEARCH} element={<Search />} />
            <Route path="/complete-profile" element={<Kyc />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route path="/card/:id" element={<RedirectToPass />} />
            <Route path="/pass/:id" element={<Card />} />
            <Route path="/pass/:id/pass-for-sale" element={<CardsForSale />} />
            <Route path="/discover/pass/:id/buy" element={<BuyEdition />} />
            {/*<Route*/}
            {/*  path={PATHS.DISCOVER_EDITION_SELL}*/}
            {/*  element={<SellEdition />}*/}
            {/*/>*/}
            <Route path={PATHS.DROPS} element={<Drops />} />
            <Route path={PATHS.DROPS_TAB} element={<Drops />} />
            {/*<Route*/}
            {/*  path="/phase/:id"*/}
            {/*  element={<Phase headerRef={headerRef} />}*/}
            {/*/>*/}
            {/*<Route path={PATHS.RESALE_CARDS} element={<ResalePhase />} />*/}
            <Route
              path={PATHS.LIMITED_EDITION_CARDS}
              element={<LimitedEdition />}
            />
            <Route path={PATHS.PROFILE} element={<NewProfile />}>
              <Route path={PATHS.POST_ID} element={<Post />} />
            </Route>
            <Route path={PATHS.PUBLIC_USER} element={<NewUserProfile />}>
              <Route path={PATHS.POST_ID} element={<Post />} />
            </Route>
            <Route path="/home/collections" element={<Collections />}>
              <Route path="passes" element={<Editions />} />
            </Route>
            <Route path={PATHS.SETTINGS} element={<Settings />}>
              <Route path="account" element={<Account />} />
              <Route path="security" element={<Security />} />
              <Route path="subscription" element={<Subscription />} />
              <Route path="subscription" element={<Subscription />} />
              <Route
                path={PATHS.LAST_SEGMENT_EMAIL_NOTIFICATIONS}
                element={<EmailNotificationsSettings />}
              />
              <Route path="transfers" element={<Transfers />} />
              <Route path="help" element={<Help />} />
              <Route path="help/faq" element={<Faq />} />
              <Route path="policies" element={<Policies />} />
            </Route>
            <Route path="/notifications" element={<Notifications />} />
            <Route path={PATHS.CHAT} element={<Messages />} />
            <Route path={PATHS.CHAT_DIRECT} element={<Messages />} />
            <Route path="/watch-list" element={<WatchList />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path={PATHS.DROPS_ID_BUY} element={<DropBuy />} />
            <Route
              path={PATHS.DROPS_ID_BUY_SUCCESS}
              element={<DropBuySuccess />}
            />
            <Route path={PATHS.DROPS_ID_SUCCESS} element={<DropBuySuccess />} />
            <Route path="/payment/accounts" element={<SelectPaymentMethod />} />
            <Route
              path="/public/user/:username/collections"
              element={<PublicCollections />}
            >
              <Route path="passes" element={<PublicEditions />} />
            </Route>
            {/*<Route*/}
            {/*  path="/public/user/:username/connections"*/}
            {/*  element={<PublicConnections />}*/}
            {/*/>*/}
            <Route path="/referrals" element={<Referrals />} />
            <Route
              path="/referrals/influencers"
              element={<ReferralsInfluencers />}
            />
            {/*<Route path="/wallet/addfunds" element={<AddFunds />}>*/}
            {/*  <Route index element={<SelectAddFundsMethod />} />*/}
            {/*  <Route path="usdc" element={<SelectAddFundsUSDCNetwork />} />*/}
            {/*  <Route path="usdc/:networkId" element={<AddFundsByUSDC />} />*/}
            {/*  <Route path="amount" element={<AddFundsAmount />} />*/}
            {/*</Route>*/}
            {/*<Route*/}
            {/*  path={PATHS.ADD_FOUNDS_USDC}*/}
            {/*  element={<SelectAddFundsUSDCNetwork />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={PATHS.ADD_FOUNDS_USDC_NETWORK_ID}*/}
            {/*  element={<AddFundsByUSDC />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={PATHS.ADD_FOUNDS_USDC_AMOUNT}*/}
            {/*  element={<AddFundsAmount />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/wallet/addfunds/success"*/}
            {/*  element={<AddFundsSuccess />}*/}
            {/*/>*/}
            {/*<Route path="/wallet/withdraw" element={<WithdrawFunds />} />*/}
            {/*<Route*/}
            {/*  path="/wallet/withdraw/type/wire"*/}
            {/*  element={<SelectWithdrawFundsAcc />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={PATHS.WALLET_WITHDRAW_TYPE_USDC}*/}
            {/*  element={<SelectWithdrawFundsUSDCNetwork />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/wallet/withdraw/type/usdc/:networkId"*/}
            {/*  element={<WithdrawFundsByUSDC />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/wallet/withdraw/success"*/}
            {/*  element={<WithdrawFundsSuccess />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/wallet/withdraw/details"*/}
            {/*  element={<WithdrawFundsDetails />}*/}
            {/*/>*/}
            {/*<Route path={PATHS.WALLET} element={<Wallet />} />*/}
            {/*<Route path="/swaps/create" element={<SwapCreateRequest />} />*/}
            {/*<Route path="/swaps/:id" element={<SwapInnerPage />} />*/}
            {/*<Route path="/swaps/incoming" element={<IncomingSwaps />} />*/}
            {/*<Route path="/swaps/sent" element={<SentSwaps />} />*/}
            <Route path={PATHS.CHALLENGES} element={<ChallengesHub />} />
            <Route
              path={PATHS.CHALLENGES_ID}
              element={<NewChallengeDetails />}
            />
            <Route
              path={PATHS.CHALLENGES_CATEGORY}
              element={<ChallengeCategory />}
            />
            <Route path={PATHS.CONNECTIONS} element={<NewConnections />} />
            <Route path={PATHS.CONNECTIONS_ID} element={<NewConnections />} />
            <Route path={PATHS.CLUBS} element={<Clubs />} />
            <Route path={PATHS.CLUBS_ID} element={<Clubs />} />
            <Route path={PATHS.FRIEND_REQUESTS} element={<FriendRequests />} />
            <Route path="/barter" element={<Barter />} />
            {process.env.REACT_APP_ENV === "production" ? null : (
              <>
                <Route path="/barter/matches" element={<Matches />} />
                <Route path="/barter/:id" element={<Swiping />} />
              </>
            )}
            <Route path={PATHS.BADGES} element={<Badges />} />
            <Route path="/add-card" element={<AddCard />} />
            <Route path="/add-bank-account/type" element={<SelectBankType />} />
            <Route path="/add-bank-account" element={<AddBankAccount />} />
            <Route
              path="/add-bank-account/bank-address"
              element={<BankAddress />}
            />
            <Route
              path="/add-card/billing-address"
              element={<BillingAddress />}
            />
            <Route path={PATHS.BLOCKED} element={<UserBlocked />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path={PATHS.FOLLOWERS} element={<InfluencerFollowers />} />
            <Route path={PATHS.MEETING} element={<MeetingRoom />} />
            <Route path={PATHS.MEETING_ID} element={<MeetingRoom />} />
            <Route
              path={PATHS.CLUB_CHALLENGES_ID}
              element={<ClubChallenges />}
            />
          </Route>
          <Route path={PATHS.DISCOVER_INFLUENCER_ID} element={<Influencer />}>
            <Route path={PATHS.TAB_ID} element={null} />
            <Route element={<RequireAuth />}>
              <Route path={PATHS.POST_ID} element={<Post />} />
            </Route>
          </Route>
          <Route element={<CheckInfluencerHandle />}>
            <Route path={PATHS.USERNAME} element={<Influencer />}>
              <Route path={PATHS.TAB_ID} element={null} />
              <Route path={PATHS.POST_ID} element={<Post />} />
            </Route>
            <Route
              path={PATHS.USERNAME_FOLLOWERS}
              element={<InfluencerFollowers />}
            />
          </Route>
          <Route path="*" element={<NoMatch />} />
          <Route path="/404" element={<NoMatch />} />
          <Route path="/500" element={<Error500 />} />
          <Route path="/503" element={<Error503 />} />
        </Routes>
      </Suspense>
      <Popup />
      <AppSnackbar />
      <AppError />
      <Onfido />
      <ChallengeSuccessModal />
      <BackdropLoader />
      <ZendeskWidget />
    </>
  );
}

export default Router;
