import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

import { useIsMobile } from "../../utils/hooks";
import { ShareIcon } from "../Modal/ShareModal/icons";
import { ShareModal } from "../Modal/ShareModal/ShareModal";
import styles from "./ShareDataBox.module.scss";

export const ShareDataBox = ({
  isText,
  url,
  children,
  buttonClassName,
  title,
  onClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  };

  const openModalHandler = async (event) => {
    event.stopPropagation();
    if (navigator.share && isMobileOrTablet()) {
      await navigator.share({
        url,
        title: title || t("discover.sharePost"),
      });
    } else {
      dispatch(
        openModal({
          children: (
            <ShareModal url={url} title={title || t("discover.sharePost")} />
          ),
          styles: {
            width: "100%",
            maxWidth: isMobile ? "254px" : "352px",
            padding: isMobile ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
          },
        })
      );
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Button
        onClick={openModalHandler}
        className={`${buttonClassName ? buttonClassName : ""} ${
          styles.shareButton
        } ${isText ? styles.shareButtonText : ""}`}
      >
        {children ? (
          children
        ) : (
          <>
            <Box className={styles.iconWrapper}>
              <ShareIcon />
            </Box>
            {isText && !isMd ? (
              <Typography>{t("drops.shareBtn")}</Typography>
            ) : null}
          </>
        )}
      </Button>
    </>
  );
};
