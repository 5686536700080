import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as SwapIcon } from "assets/svg/barter-icon.svg";
import { ReactComponent as CardsIcon } from "assets/svg/cards-icon.svg";
import { ReactComponent as DisableSwapIcon } from "assets/svg/disable-barter-icon.svg";
import { ReactComponent as IconHashscan } from "assets/svg/icon-hashscan.svg";
import { ReactComponent as IconRedirect } from "assets/svg/icon-redirect.svg";
import { ReactComponent as IconTrophy } from "assets/svg/icon-trophy.svg";
import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import { ReactComponent as ShareIcon } from "assets/svg/share-icon2.svg";
import { ReactComponent as TrophyIconWhite } from "assets/svg/trophy-icon-white.svg";
import { ReactComponent as WithdrawIcon } from "assets/svg/withdraw-card.svg";
import WithdrawCardDlg from "components/Modal/WithdrawCardDlg/index.jsx";
import Proptypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import { LocalizationContext } from "services/localizationContext";
import { hederaLink } from "utils";
import { useIsMobile } from "utils/hooks";

// import CardLevelIcon from "../CardLevelIcon";
import styles from "./EditionCard.module.scss";
//Todo: need to refactor this code and code in the Profile view component and Editions component (many duplicates, need to encapsulate logic in this component)
const EditionCard = ({
  id,
  influencerName,
  cardImage,
  latestPrice,
  priceChangePct,
  currencySymbol,
  className,
  wrapClassName,
  textBoxClassName,
  isTrophy,
  onClick,
  editionsDropped,
  showOptsBtn,
  swapEditionCb,
  makeTrophyCard,
  toggleBarterEnabled,
  isSwappable,
  isPublicUser,
  barterDisabled,
  influencerLevel,
  tokens,
  inBarter,
  quantityAllocated,
  handleWithdrawNft,
  withdrawDlgId,
  seWithdrawDlgId,
}) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOptsClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const publicLink = `${window.origin}/pass/${id}`;

  const shareEdition = (ev) => {
    ev.stopPropagation();

    dispatch(
      setSnackbar({
        open: true,
        message: t("default.linkCopied"),
      })
    );
    navigator.clipboard.writeText(publicLink);

    setAnchorEl(null);
  };

  const handleRedirectBlockscaner = (ev) => {
    ev.stopPropagation();

    const token = tokens[0]?.split("-")[0];

    if (!token) return;

    window.open(`${hederaLink()}/${token}`, "_blank");
  };

  const handleSwap = (ev) => {
    ev.stopPropagation();
    swapEditionCb();

    setAnchorEl(null);
  };

  const handleMakeTrophyCard = (ev) => {
    ev.stopPropagation();
    makeTrophyCard();
    setAnchorEl(null);
  };

  const toggleBarter = (ev) => {
    ev.stopPropagation();
    toggleBarterEnabled();

    setAnchorEl(null);
  };

  return (
    <>
      <Box
        style={{ position: "relative" }}
        className={`${wrapClassName ? wrapClassName : ""} ${styles.card} ${
          open ? styles.cardMenu : ""
        } ${showOptsBtn ? styles.cardOption : ""}`}
        onClick={onClick}
      >
        <Box className={[styles.container, className]}>
          <Box className={styles.overlay} />
          <img className={styles.image} src={cardImage} alt={influencerName} />
          <Box className={styles.cardHeader}>
            {/*{inBarter ? (*/}
            {/*  <Box className={styles.barterStatusBLock}>*/}
            {/*    <Typography*/}
            {/*      variant="body3"*/}
            {/*      component="span"*/}
            {/*      className={styles.barterStatusText}*/}
            {/*    >*/}
            {/*      {t("barter.inBarter")}*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*) : null}*/}
            {isTrophy && (
              <IconButton className={styles.iconBtn}>
                <IconTrophy />
              </IconButton>
            )}
            {/*{!isSwappable && (*/}
            {/*  <IconButton className={styles.iconBtn}>*/}
            {/*    <DisableSwapIcon className={styles.swappableIcon} />*/}
            {/*  </IconButton>*/}
            {/*)}*/}
          </Box>
        </Box>
        <Box
          className={`${styles.textBox} ${
            textBoxClassName ? textBoxClassName : ""
          }`}
        >
          {/*{!!editionsDropped && (*/}
          {/*  <Typography*/}
          {/*    variant="body2"*/}
          {/*    component="span"*/}
          {/*    className={styles.overline}*/}
          {/*  >*/}
          {/*    {t("default.editionNumber", { number: editionsDropped })}*/}
          {/*  </Typography>*/}
          {/*)}*/}
          <Box className={styles.cardFooter}>
            <Box className={styles.cardFooterTop}>
              <Typography className={styles.title} variant="textMediumBold">
                {influencerName}
              </Typography>
              {showOptsBtn ? (
                <IconButton
                  className={styles.optsBtn}
                  onClick={handleOptsClick}
                >
                  <Kebab className={styles.optsBtnIcon} />
                </IconButton>
              ) : null}
            </Box>
            <Box className={styles.cardFooterBottom}>
              {tokens && tokens?.length > 1 && (
                <Typography
                  variant="clash_display_12_500"
                  className={styles.cardBox}
                >
                  <CardsIcon />x{tokens.length}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        {showOptsBtn && (
          <Popover
            id={"edition-options-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            className={styles.optsPaper}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {/*{!barterDisabled && !!swapEditionCb && (*/}
            {/*  <Button*/}
            {/*    onClick={handleSwap}*/}
            {/*    variant="text"*/}
            {/*    className={styles.popoverBtn}*/}
            {/*  >*/}
            {/*    <SwapIcon className={styles.popoverBtnIcon} />*/}
            {/*    <Typography variant="body2" className={styles.popoverBtnText}>*/}
            {/*      {t("edition.swapCard")}*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*)}*/}
            {!isPublicUser && !!makeTrophyCard && (
              <Button
                onClick={handleMakeTrophyCard}
                variant="text"
                className={styles.popoverBtn}
              >
                <TrophyIconWhite className={styles.popoverBtnIcon} />
                <Typography variant="body2" className={styles.popoverBtnText}>
                  {t("edition.makeTrophyCard")}
                </Typography>
              </Button>
            )}
            {/*{!isPublicUser && (*/}
            {/*  <Button*/}
            {/*    onClick={toggleBarter}*/}
            {/*    variant="text"*/}
            {/*    className={styles.popoverBtn}*/}
            {/*  >*/}
            {/*    {isSwappable ? (*/}
            {/*      <DisableSwapIcon className={styles.popoverBtnIcon} />*/}
            {/*    ) : (*/}
            {/*      <SwapIcon className={styles.popoverBtnIcon} />*/}
            {/*    )}*/}
            {/*    <Typography variant="body2" className={styles.popoverBtnText}>*/}
            {/*      {isSwappable*/}
            {/*        ? t("edition.makeUnavailableForBarter")*/}
            {/*        : t("edition.makeAvailableForBarter")}*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*)}*/}
            {/*{!isPublicUser && (*/}
            {/*  <Button*/}
            {/*    onClick={(e) => {*/}
            {/*      setAnchorEl(null);*/}
            {/*      e.stopPropagation();*/}
            {/*      handleWithdrawNft(id);*/}
            {/*    }}*/}
            {/*    variant="text"*/}
            {/*    className={styles.popoverBtn}*/}
            {/*  >*/}
            {/*    <WithdrawIcon className={styles.popoverBtnIcon} />*/}
            {/*    <Typography variant="body2" className={styles.popoverBtnText}>*/}
            {/*      {t("edition.withdraw")}*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button
              onClick={shareEdition}
              variant="text"
              className={styles.popoverBtn}
            >
              <ShareIcon className={styles.popoverBtnIcon} />
              <Typography variant="body2" className={styles.popoverBtnText}>
                {t("edition.share")}
              </Typography>
            </Button>
            {!!tokens?.length && (
              <Button
                onClick={handleRedirectBlockscaner}
                variant="text"
                className={styles.popoverBtn}
              >
                <IconHashscan className={styles.popoverBtnIcon} />
                <Box>
                  <Typography
                    variant="body2"
                    className={`${styles.popoverBtnText} ${styles.hashscanText}`}
                  >
                    {t("edition.viewHashscan")}
                  </Typography>
                  <IconRedirect className={styles.iconRedirect} />
                </Box>
              </Button>
            )}
          </Popover>
        )}
      </Box>
      {withdrawDlgId === id ? (
        <WithdrawCardDlg
          card={{
            imageUrl: cardImage,
            series: editionsDropped,
            influencerName: influencerName,
            tokens: tokens,
            quantityAllocated: quantityAllocated,
          }}
          close={() => seWithdrawDlgId(null)}
        />
      ) : null}
    </>
  );
};

EditionCard.defaultProps = {
  name: "",
  editionsDropped: 0,
  cardImage: null,
  currencySymbol: "$",
  latestPrice: 0,
  priceMovement: 0,
};

EditionCard.propTypes = {
  name: Proptypes.string,
  editionsDropped: Proptypes.number,
  cardImage: Proptypes.string,
  currencySymbol: Proptypes.string,
  latestPrice: Proptypes.number,
  priceMovement: Proptypes.number,
};

export default EditionCard;
