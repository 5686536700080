import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import { getChallengesSelector } from "redux/selectors/challengesSelector";
import {
  getMyConnections,
  getMyConnectionsLoaderSelector,
  getMyFollowersLoaderSelector,
  getMyFollowersSelector,
  getPublicUserCommunity,
  getPublicUserCommunityLoading,
  getPublicUserFriends,
  getPublicUserFriendsLoading,
  getPublicUserFriendsTotal,
  getUserInviteCode,
} from "redux/selectors/usersSelectors";
import {
  fetchFollowingByUserId,
  fetchFriendsById,
  fetchMyFollows,
  fetchUserConnections,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { CONNECTIONS_LOAD_LIMIT } from "../../../../constants";
import { BadgesWrapper } from "../BadgesWrapper";
import { ClubsWrapper } from "../ClubsWrapper";
import { FriendsWrapper } from "../FriendsWrapper";
import { ProfileInfo } from "../ProfileInfo/ProfileInfo";

export const Sidebar = ({
  level,
  profileImageUrl,
  username,
  isInfluencer,
  rewardPoints,
  stats,
  isPublic,
  me = {},
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isS = useMediaQuery("(max-width:960px)");

  const clubsCommunity = useSelector(getMyFollowersSelector);
  const clubsLoading = useSelector(getMyFollowersLoaderSelector);
  const connections = useSelector(getMyConnections);
  const connectionsLoading = useSelector(getMyConnectionsLoaderSelector);
  const userInviteCode = useSelector(getUserInviteCode);
  const challenges = useSelector(getChallengesSelector);

  const publicUserClubsCommunity = useSelector(getPublicUserCommunity);
  const publicUserClubsLoading = useSelector(getPublicUserCommunityLoading);
  const publicUserFriends = useSelector(getPublicUserFriends);
  const publicUserFriendsTotal = useSelector(getPublicUserFriendsTotal);
  const publicUserFriendsLoading = useSelector(getPublicUserFriendsLoading);

  let challengesArray = [];
  let convertedData = [];

  if (!isPublic) {
    challengesArray = Object.entries(challenges);

    convertedData = challengesArray.reduce((acc, current) => {
      current[1].forEach((item) => {
        const badge = item?.appUserTrack?.challengeStage?.badge;
        const currentBadge = item?.currentBadge;
        if (badge && currentBadge) {
          acc.push({
            picture: badge.imageUrl,
            title: t(`badges.${badge.code}`),
            id: badge.id,
            stageNum: currentBadge.stageNum,
            goalCount: currentBadge.goalCount,
          });
        }
        return acc;
      });
      return acc;
    }, []);
  }

  useEffect(() => {
    if (!isPublic) {
      dispatch(fetchMyFollows());
      dispatch(
        fetchUserConnections({
          limit: CONNECTIONS_LOAD_LIMIT,
          page: 1,
        })
      );
      dispatch(fetchChallenges());
    } else {
      dispatch(fetchFriendsById({ id: me.id }));
      dispatch(fetchFollowingByUserId({ id: me.id }));
    }
  }, [isPublic, me.id]);

  return (
    <>
      <ProfileInfo
        data={{
          level,
          profileImageUrl,
          username,
          appUser: {
            isInfluencer,
          },
          rewardPoints,
          badgesAmount: stats.badges,
          clubsAmount: isPublic
            ? publicUserClubsCommunity?.length
            : clubsCommunity?.length,
          friendsAmount: isPublic ? publicUserFriendsTotal : connections?.total,
          stats,
          userInviteCode,
          isPublic,
          me,
        }}
      />
      {!isS ? (
        <>
          <ClubsWrapper
            clubsLoading={isPublic ? publicUserClubsLoading : clubsLoading}
            clubsCommunity={
              isPublic ? publicUserClubsCommunity : clubsCommunity
            }
            isPublic={isPublic}
            username={username}
            me={me}
          />
          <FriendsWrapper
            connections={isPublic ? publicUserFriends : connections}
            userInviteCode={userInviteCode}
            loading={isPublic ? publicUserFriendsLoading : connectionsLoading}
            isPublic={isPublic}
            me={me}
          />
          {!isPublic ? <BadgesWrapper convertedData={convertedData} /> : null}
        </>
      ) : null}
    </>
  );
};
